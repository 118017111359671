import * as React from "react"
import "./testimonial.scss"
import classnames from "classnames"

export default function Testimonial(props) {
  return (
    <div className={props.class} id={`testimonial-${props.idx}`}>
      <div
        className={classnames(`testimonial ${props.side}`, {
          "testimonial--active": props.isActiveOnMobile,
        })}
      >
        <div className="web-p1-regular">{props.text}</div>
        <div className="testimonial-author">
          <div className="testimonial-author__img-container">
            <img src={props.image} alt={props.company} />
          </div>
          <div className="web-p2-regular">
            {props.name}, <br /> {props.company}
          </div>
        </div>
      </div>
    </div>
  )
}
