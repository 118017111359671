import "./modal.scss"
import * as React from "react"
import classnames from "classnames"
import axios from "axios"
import arrowLeft from "./../../icons/arrows/leftWhite.svg"
import { ModalContext } from "../layout"
import Container from "../Container/Container"
import logo from '../../icons/mountain-pass/logo.svg'

const checkboxVariants = [
  { name: "Consulting" },
  { name: "Web development" },
  { name: "Smart Contracts" },
  { name: "NFTs" },
  { name: "APIs & Integration" },
  { name: "Other" },
]

const initialFormData = {
  interested_in: [],
  budget_usd: "",
  full_name: "",
  email_address: "",
  tell_us_about_project: "",
}

const budgetVariants = [
  { title: "5–50k" },
  { title: "50–100k" },
  { title: "100–200k" },
  { title: "200k +" },
]

const regExp =
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"

export default function Modal() {
  const modalRef = React.useRef()
  const textAreaRef = React.useRef()
  const [formData, setFormData] = React.useState(initialFormData)
  const [isEmailValid, setIsEmailValid] = React.useState(false)
  const [isEmailInputDirty, setIsEmailInputDirty] = React.useState(false)
  const { isModalOpened, setIsModalOpened } = React.useContext(ModalContext)

  const closeModal = () => {
    setIsModalOpened(false)
  }

  React.useEffect(() => {
    window.addEventListener("resize", setTextAreaHeight)
    setTextAreaHeight()

    return () => {
      window.removeEventListener("resize", setTextAreaHeight)
    }
  }, [])

  const onClickCheckButton = id => {
    const list = formData.interested_in.filter(item => item !== id)
    if (list.length === formData.interested_in.length) {
      setFormData({
        ...formData,
        interested_in: [...formData.interested_in, id],
      })
    } else {
      setFormData({ ...formData, interested_in: list })
    }
  }

  const validateEmailInput = () => {
    let isInvalid = formData.email_address
      ? formData.email_address.match(regExp) === null
      : true
    setIsEmailValid(!isInvalid)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleEmailInputChange = e => {
    handleChange(e)
    validateEmailInput()
  }

  const handleBlur = () => {
    validateEmailInput()
    setIsEmailInputDirty(true)
  }

  function handleTextareaChange(e) {
    handleChange(e)
    setTextAreaHeight()
  }

  function setTextAreaHeight() {
    const textArea = textAreaRef.current
    textArea.style.height = "auto"
    textArea.style.height = `${textArea.scrollHeight}px`
  }

  const handleSubmit = async e => {
    e.preventDefault()

    validateEmailInput()
    setIsEmailInputDirty(true)

    if (isEmailValid) {
      // console.debug(formData)
      let interestedIn = ''
      if (formData.interested_in.length > 0) {
        formData.interested_in.forEach((i) => interestedIn += (i + ', '))
      }
      // const interestedIn = formData.interested_in.length > 0 ? formData.interested_in.forEach((in) => interedtedInStr += in) : ''
      const slackMessage = {
        text: `${formData.full_name} wants to talk!`,
        blocks: [
          {
            type: 'section',
            blockId: 'section_email',
            text: {
              type: 'mrkdwn',
              text: `Email: ${formData.email_address}`
            }
          },
          {
            type: 'section',
            blockId: 'section_interested_in',
            text: {
              type: 'mrkdwn',
              text: `Interested In: ${formData.interested_in}`
              // text: `Interested In: ${interestedIn}`
            }
          },
          {
            type: 'section',
            blockId: 'section_budget',
            text: {
              type: 'mrkdwn',
              text: `Budget: ${formData.budget_usd}`
            }
          },
          {
            type: 'section',
            blockId: 'section_project',
            text: {
              type: 'mrkdwn',
              text: `Project: ${formData.tell_us_about_project}`
            }
          },
        ]
      }

      const messageJson = JSON.stringify(slackMessage)
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: messageJson
      };

      // TODO: replce local serverless URL
      const localServerless = 'https://8ah1hdt04a.execute-api.ap-southeast-2.amazonaws.com/dev/slack'

      axios
      .post(localServerless, formData)
      .then(response => {
        // console.log(response.data.message)
        setFormData(initialFormData)
        closeModal()
      }).catch((err) => console.error(err))
    }
  }

  return (
    <div
      ref={modalRef}
      className={classnames("modal", { active: isModalOpened })}
    >
      <div className="modal__content">
        <div className="modal__content__header">
          <img className="modal__content__header__img" src={logo} alt="" />
          <div className="modal__content__header__text">Mountain Pass</div>
        </div>
        <div className="modal__content__inner-wrapper">
          <div className="d-flex">
            <div className="left-part">
              <div className="left-part__wrapper">
                <div className="web-button-small" onClick={closeModal}>
                  <img className="arrow-left" alt="" />
                  back
                </div>
                <div className="web-h4"> CONTACT US </div>
                <div className="web-h1"> Let’s talk! </div>
                <img className="star rotating" alt="" />
              </div>
              <div className="modal__go-back-btn web-h4" onClick={closeModal}>
                <img src={arrowLeft} alt="" />
                go back
              </div>
            </div>
            <div className="right-part">
              <form onSubmit={handleSubmit}>
                <div className="modal-reason-tab">
                  <div className="web-h3"> Contact us to </div>
                  <div className="all-buttons">
                    {checkboxVariants.map((item, idx) => (
                      <input
                        type="button"
                        className={classnames("toggle-button", {
                          active:
                            formData.interested_in.indexOf(item.name) !== -1,
                        })}
                        onClick={() => onClickCheckButton(item.name)}
                        value={item.name}
                        key={idx}
                      />
                    ))}
                  </div>
                </div>
                <div className="budget-buttons-tab">
                  <div className="web-h3">Project budget (USD)</div>
                  <div className="budget-toggle-buttons">
                    {budgetVariants.map((item, idx) => (
                      <div
                        key={idx}
                        className={classnames("budget-toggle-button", {
                          active: formData.budget_usd === item.title,
                        })}
                        onClick={() =>
                          setFormData({ ...formData, budget_usd: item.title })
                        }
                      >
                        {item.title}
                      </div>
                    ))}
                  </div>
                </div>
                <input
                  type="text"
                  placeholder="Your full name"
                  value={formData.full_name}
                  name="full_name"
                  onChange={handleChange}
                />
                <div
                  className={classnames("modal-email-block", {
                    error: !isEmailValid && isEmailInputDirty,
                  })}
                >
                  <input
                    type="text"
                    onBlur={handleBlur}
                    onInput={handleEmailInputChange}
                    placeholder="Your email"
                    name="email_address"
                    value={formData.email_address}
                  />
                  <div className="web-p3-regular error-info">
                    Invalid email value
                  </div>
                </div>
                <textarea
                  onChange={handleTextareaChange}
                  placeholder="Tell us about your project"
                  name="tell_us_about_project"
                  value={formData.tell_us_about_project}
                  rows={1}
                  className="modal__textArea"
                  ref={textAreaRef}
                />

                <div className="submit-button-input">
                  <input
                    className="web-button-text"
                    type="submit"
                    value="Send request"
                  />
                  <img className="arrow-top-right" alt="" />
                </div>

                <div className="web-p3-regular">
                  By sending request you accept our&nbsp;
                  <a
                    href="https://www.mountain-pass.com.au/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    privacy policy
                  </a>
                  &nbsp;and&nbsp;
                  <a
                    href="https://www.mountain-pass.com.au/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms of use
                  </a>
                  .
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
