import * as React from "react"
import "./portfolio-card.scss"
import classNames from 'classnames';

function PortfolioCard ( props ) {

    return (
        <div className={classNames('portfolio-card', {
            'portfolio-card--active': props.active,
            'portfolio-card--next': props.next,
        })}>

            <img src={props.image} alt={props.title} className="portfolio-card__image"/>

            <div className={classNames('portfolio-card__text', 'web-h1', {
                'portfolio-card__text--active': props.active
            })}
                > {props.title} </div>
        </div>
    );
}

export default PortfolioCard