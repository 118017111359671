import React from "react"
import PortfolioMobilePage from "../PortfolioMobile/portfolio-mobile"
import PortfolioPage from "../Portfolio/portfolio-page"
import "./portfolio-wrapper.scss"
import images from "../Portfolio/portfolioCardImages"
import Category from "../Category/category"

export default () => {
  const textMock =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Commodo sed egestas egestas fringilla."

  const technologiesMock = [
    {
      title: "Figma",
      icon: "figma",
    },
    {
      title: "VueJS",
      icon: "vue",
    },
    {
      title: "JavaScript",
      icon: "javascript",
    },
    {
      title: "ReactJS",
      icon: "react",
    },
  ]

  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const items = [
    { title: "Super \nrewards", image: images.superRewards, description: "Grow your super wih your everyday spend! Shop instore and online via super rewards platform to earn commission that get paid into your superannuation directly." },
    { title: "Safeentry", image: images.safeentry, description: "Visitor management made easy. SafeEntry offers digital check-in across venues and people for one easy, seamless experience. " },
    { title: "Regional \nAustralia \nbank", image: images.australiaBank, description: "Experience Australia's exciting new Consumer Data Right (CDR) and access your data faster and easier than ever before." },
    { title: "Global \nsign.in", image: images.signIn, description: "Leading event technology provider with clients worldwide who rely on their expertise for the most important events." },
    { title: "Kneerom", image: images.kneerom, description: "Kneerom is a mobile app that helps you to track your knee pain and recovery using Artificial Intelligence." },
    { title: "Addressr", image: images.addressr, description: "Free Australian address validation, search and autocomplete. Softwarre As or NOT as a service. We love SaaS, but we know its not for everyone." },
    { title: "Sipora", image: images.sipora, description: "Sipora is Australia's leading round-up as a service platform. Sipora enables organisations and NFP's to launch a round-up product." },
    { title: "Handle & \nkate", image: images.handleKate, description: "Handle & Kate design and handmake porcelain architectural hardware ethically and sustainably for your home." },
    { title: "My stay \nwell", image: images.stayWell, description: "Discharge planning tool designed for Ramsay mental health patients to support their transition from a hospital admission." },
    { title: "My \nRamsay", image: images.myRamsay, description: "My Ramsay is a real-time communication tool for the health care professionals working in the largest private health network, Ramsay Health Care, in Australia." },
    { title: "My \npatient +", image: images.myPatient, description: "MyPatient+ is an app that provide important, doctor-centric, real-time patient information to clinicians anywhere, anytime." },
  ].map(e => ({
    ...e,
    tech: technologiesMock.slice(0, randomIntFromInterval(1, 4)),
  }))

  return (
    <Category categoryTitle="portfolio">
      <PortfolioMobilePage items={items} />
      <PortfolioPage items={items} />
    </Category>
  )
}
