import * as React from "react"
import Category from "../Category/category"
import Testimonial from "./testimonial"
import "./testimonial.scss"
import useActiveOnMobile from "../../hooks/useObserver"
import rabLogo from '../../images/testimonial/rab.png';
import siporaLogo from '../../images/testimonial/sipora.png';
import superrewardsLogo from '../../images/testimonial/superrewards.png';

const testimonialPageData = [
  {
    side: "left",
    image: siporaLogo,
    text: "Mountain Pass well and truly over delivered on the original scope and are a valuable development partner to our business.",
    name: "Jonathan Despinidic",
    company: "Sipora, Co-Founder",
    class: "jonathan",
  },
  {
    side: "left-right",
    image: superrewardsLogo,
    text: "Mountain-Pass have been a joy to work with. From the very first meeting until our platform was launched to market, their level of detail, communication and streamlined technology was first class. Scoping documents were very comprehensive, the build was exactly as per timelines, and it was truly a collaborative effort. They kept calm under pressure, and always delivered above and beyond expectations.",
    name: "Pascale Helyar-Moray",
    company: "Super Rewards",
    class: "pascale",
  },
  {
    side: "right-left",
    image: rabLogo,
    text: "It has been fantastic working with Sam and the team at Mountain Pass on delivering some crucial components of our Australian first Consumer Data Rights data recipient implementation. Their ability to adapt, be pro-active and deliver in a pressure-filled environment was impressive! Super competent with the tech, level headed, helpful, friendly, clever and easy to work with. Looking forward to many more projects with Mountain Pass!",
    name: "Matt Peterson",
    company: "Regional Australia Bank",
    class: "matt",
  },
]

function TestimonialPage() {
  const activeOnMobile = useActiveOnMobile("testimonial")

  return (
    <Category categoryTitle={"testimonials"}>
      <div className="web-h1 testimonials__title">
        Client’s <br /> kind words
        <img className="star rotating" alt="" />
      </div>

      {testimonialPageData.map((testimonial, idx) => (
        <Testimonial
          side={testimonial.side}
          image={testimonial.image}
          text={testimonial.text}
          name={testimonial.name}
          company={testimonial.company}
          class={testimonial.class}
          key={idx}
          idx={idx}
          isActiveOnMobile={activeOnMobile === idx}
        />
      ))}
    </Category>
  )
}

export default TestimonialPage
