import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Welcome from "../components/Welcome/welcome"
import TestimonialPage from "../components/Testimonials/testimonial-page"
import BlogPage from "../components/Blog/blog-page"
import ContactsPage from "../components/Contacts/contacts-page"
import ServicePage from "../components/Services/service-page"
import PortfolioPage from "../components/PortfolioWrapper/portfolio-wrapper"
import Modal from "../components/Modal/modal"
import Container from "../components/Container/Container"
import "../styles/default.scss"
import "../styles/variables.scss"

function IndexPage() {
  return (
    <div className="main-page">
      <Layout>
        <Seo title="Web and Mobile Development Solutions" />
        <Modal />
        <Welcome />
        <Container>
          <PortfolioPage />
          <ServicePage />
          <TestimonialPage />
          {/* <BlogPage /> */}
          <ContactsPage />
        </Container>
      </Layout>
    </div>
  )
}

export default IndexPage
