import * as React from "react"
import { useState } from "react"
import classNames from "classnames"

import Category from "../Category/category"
import ServiceCard from "./service-card"

import "./service-page.scss"
import serviceIcons from "./serviceIcons"
import BREAKPOINTS from "../../constants/breakpoints";

const items = [
  { title: "Consulting", icon: serviceIcons.consulting, text: "At Mountain Pass, we provide comprehensive assistance and guidance to ensure the continuity and prosperity of your business. Leveraging our established history of delivering scalable and secure platforms to both startups and enterprises, we possess the expertise to aid in the expansion of your business. Our team conducts a thorough evaluation of your present state and identifies any areas for improvement. We are equipped with a wide range of digital solutions to address all your business needs." },
  { title: "Web \ndevelopment", icon: serviceIcons.web, text: "Our specialized teams are capable of offering the necessary expertise to establish a consistent and seamless user experience across your selected platforms, through a tailored integration that enhances website engagement, provides valuable information and ensures responsiveness."},
  { title: "Smart \ncontracts", icon: serviceIcons.blockchain, text: "Smart contracts are a fundamental component of both DeFi and NFT projects, as they establish the operational guidelines for the project. Our team utilizes a comprehensive approach that incorporates industry best practices, rigorous security auditing, and meticulous testing to ensure that our smart contracts are optimally configured for production deployment, thus facilitating swift and efficient launch cycles." },
  { title: "NFTs", icon: serviceIcons.nft, text: "NFTs offer the potential to establish smart contracts that guarantee continuing royalty payments. In addition, we develop custom websites that align with our clients' branding, seamlessly integrate with pre-existing marketplaces and platforms, provide access to a worldwide market for increased customer reach, track industry trends, offer NFTs backed by tangible assets, facilitate generative artworks, and offer numerous other benefits." },
  { title: "APIs & \nIntegration", icon: serviceIcons.integration, text: "We offer a range of API and integrations services to help businesses connect their systems and applications with other platforms, enabling seamless data transfer and streamlining workflows. Our team of experts can help design, develop, and implement custom APIs and integrations tailored to your specific needs and requirements, ensuring maximum efficiency and productivity. Whether you need to integrate with popular third-party services or build custom integrations from scratch, we've got you covered." },
  { title: "Other", icon: serviceIcons.other, text: "Please contact us for any other bespoke software development." },
]

export default function ServicePage() {
  const [activeIndex, setActiveIndex] = useState(0)
  const container = React.createRef()
  const cards = React.createRef()

  function onActiveItemChange(param, mode = "offset") {
    const nextIdx = mode === "offset" ? activeIndex + param : param
    setActiveIndex(nextIdx);

    if (window.screen.width > BREAKPOINTS.MOBILE_MAX) {
      const previousCardsWidth = 272 * nextIdx + (window.screen.width * 0.07);
      cards.current.scrollLeft = previousCardsWidth - 237;
    }
  }

  return (
    <Category categoryTitle={"services"} className="services">
      <div className="buttons">
        <button
          onClick={() => onActiveItemChange(-1)}
          disabled={activeIndex === 0}
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            xmlns="http://www.w3.org/2000/svg"
            className="bottom-left-icon"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.20902 19.3637L21.5883 1.98449L20.0154 0.411621L2.63615 17.7909L2.63615 1.19805H0.411783L0.411784 21.5881H20.8018L20.8018 19.3637H4.20902Z"
              fill="white"
            />
          </svg>
        </button>
        <button
          onClick={() => onActiveItemChange(1)}
          disabled={activeIndex === 5}
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            xmlns="http://www.w3.org/2000/svg"
            className="top-right-icon"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.791 2.63603L0.411743 20.0153L1.98461 21.5881L19.3638 4.2089L19.3638 20.8017H21.5882L21.5882 0.411665H1.19818L1.19818 2.63603H17.791Z"
              fill="white"
            />
          </svg>
        </button>
      </div>

      <div className="service-cards" ref={cards}>
        <div className="container" ref={container}>
          {items.map((item, index) => (
            <ServiceCard
              key={index}
              className={classNames({ active: activeIndex === index })}
              click={() => onActiveItemChange(index, "index")}
              number={index + 1}
              title={item.title}
              icon={item.icon}
              text={item.text}
            />
          ))}
        </div>
      </div>
    </Category>
  )
}
