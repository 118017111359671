import * as React from "react"
import "./portfolio.scss"
import PortfolioCard from "./PortfolioCard/portfolio-card"
import classnames from "classnames"

import "./portfolio.scss"

import star from "../../icons/testimonial/star.svg"

function PortfolioPage({ items }) {
  const itemsLength = items.length

  const [activeIndex, setActiveIndex] = React.useState(0)

  const getIndex = param => {
    const index = activeIndex + param

    if (index < 0) return itemsLength - 1
    if (index >= itemsLength) return 0
    return index
  }

  const nextItemIdx = () => {
    return getIndex(1)
  }

  const formatIdxToNumber = idx => {
    const num = idx + 1

    return num > 9 ? num : `0${num}`
  }

  return (
    <div className="portfolio--desktop">
      {items.map((item, idx) => (
        <p
          className={classnames("portfolio--desktop__description", {
            "portfolio--desktop__description--active": idx === activeIndex,
          })}

          key={idx}
        >
          {item.description}
        </p>
      ))}

      <div className="portfolio-container">
        <div className="portfolio__items">
          <div className="portfolio__items-container">
            {items.map((item, idx) => (
              <PortfolioCard
                title={item.title}
                image={item.image}
                active={idx === activeIndex}
                next={idx === nextItemIdx()}
                key={idx}
              />
            ))}
          </div>
        </div>

        <div className="portfolio-checker">
          <img className="rotating" src={star} alt="" />

          <div
            className={classnames(
              "web-p3-medium",
              "portfolio-checker__active-idx"
            )}
          >
            {formatIdxToNumber(activeIndex)}
          </div>

          {/* <p className="portfolio-checker__text">
            Site layout <br />
            animation in JS
          </p> */}

          <div className="buttons">
            <button onClick={() => setActiveIndex(getIndex(-1))}>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                xmlns="http://www.w3.org/2000/svg"
                className="bottom-left-icon"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.20902 19.3637L21.5883 1.98449L20.0154 0.411621L2.63615 17.7909L2.63615 1.19805H0.411783L0.411784 21.5881H20.8018L20.8018 19.3637H4.20902Z"
                  fill="white"
                />
              </svg>
            </button>
            <button onClick={() => setActiveIndex(getIndex(1))}>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                xmlns="http://www.w3.org/2000/svg"
                className="top-right-icon"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.791 2.63603L0.411743 20.0153L1.98461 21.5881L19.3638 4.2089L19.3638 20.8017H21.5882L21.5882 0.411665H1.19818L1.19818 2.63603H17.791Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
          <div className="web-p2-medium">
            {` ${formatIdxToNumber(activeIndex)} / ${itemsLength} `}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PortfolioPage
