import * as React from "react";
import BREAKPOINTS from "../constants/breakpoints";

function useActiveOnMobile(idFragment) {
    const [activeOnMobile, setActiveOnMobile] =
        React.useState(null)

    const observerHandler = entries => {
        if (window.screen.width > BREAKPOINTS.MOBILE_MAX) {
            setActiveOnMobile(null)
            return
        }

        entries.forEach(({ target, isIntersecting }) => {
            if (!isIntersecting) {
                return
            }

            const [, idx] = target.id.split("-")

            setActiveOnMobile(+idx)
        })
    }

    let observer = null

    if (typeof window !== "undefined") {
        observer = new IntersectionObserver(observerHandler, { threshold: 0.5 })
    }

    const processNodes = callback => {

        const elementsIdString = `${idFragment}-`;

        const elements = document.querySelectorAll(`[id^=${elementsIdString}]`)

        for (let i = 0; i < elements.length; i++) {
            callback(elements[i])
        }
    }

    React.useEffect(() => {
        if (observer) {
            processNodes(el => observer.observe(el))
        }

        return () => {
            if (observer) {
                processNodes(el => observer.unobserve(el))
                observer.disconnect()
            }
        }
    }, [])

    return activeOnMobile
}

export default useActiveOnMobile;