import * as React from "react"
import "./service-card.scss"

const defaultText =
  " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Commodo sed egestas egestas fringilla."

function ServiceCard(props) {
  return (
    <div
      className={`service-card ${props.className}`}
      onClick={() => props.click()}
    >

      <div className="service-card__content">
        <div className="web-p3-medium service-card__number">
          {`0${props.number}`}
        </div>
        <div className="web-h2 service-card__title"> {props.title}</div>
        <div className="web-p1-regular">
          {props.text ? props.text : defaultText}
        </div>
      </div>

      <img src={props.icon} alt={props.title} className="service-card__icon" />
    </div>
  )
}

export default ServiceCard
