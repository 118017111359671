import * as React from "react"
import classnames from "classnames"
import "./portfolio-mobile.scss"

export default function PortfolioMobileCard(props) {
  return (
    <figure
      className={classnames("portfolio-mobile-card", {
        active: props.active,
        next: props.next,
        prev: props.prev,
      })}
    >
      <img className="star rotating" alt="" />

      <img
        src={props.image}
        alt={props.title}
        className="portfolio-mobile-card__image"
      />
    </figure>
  )
}
