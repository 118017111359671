import * as React from "react"
import "./welcome.scss"
import DesktopVectors from "../../images/welcome/vectors-desktop.svg"
import MobileVectors from "../../images/welcome/vectors-mobile.svg"
import MobileVectorBottom from "../../images/welcome/vector-mobile-bottom.svg"
import ContactUsButton from "../ContactUsButton/contact-us-button"
import { ModalContext } from "../layout"

function Welcome() {
  const { setIsModalOpened } = React.useContext(ModalContext)

  return (
    <div className="welcome main-page" id="welcome">
      <img src={MobileVectors} alt="" className="welcome__mobile-vector" />

      <img
        src={MobileVectorBottom}
        alt=""
        className="welcome__mobile-vector welcome__mobile-vector-bottom"
      />

      <span className="welcome__mobile-vector-filler" />

      <div className="welcome__container">
        <div className="welcome__vertical-line-above-mountain" />

        <div className="welcome__title-bar">
          <div className="welcome__title-bar__lines">
            {[...Array(3)].map((_, index) => (
              <span key={index} />
            ))}
          </div>

          <h1 className="welcome__title">
            Development <br />
            for your <br />
            business
          </h1>

          <p>
            <span className="welcome__horizontal-line">
              <span className="welcome__circle" />
            </span>

            <span className="web-p1-regular">and even more</span>
          </p>
        </div>

        <div className="welcome__illustration-container">
          <img src={DesktopVectors} alt="" className="welcome__illustration" />
        </div>

        <div className="welcome__button-container">
          <ContactUsButton
            enableBlueCircle={false}
            onClick={() => setIsModalOpened(true)}
            fill
          />
        </div>
      </div>
    </div>
  )
}

export default Welcome
