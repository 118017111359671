import * as React from "react"
import "./contacts-page.scss"
import Category from "../Category/category"
import ContactUsButton from "../ContactUsButton/contact-us-button"
import { ModalContext } from "../layout"

function ContactsPage() {
  const { setIsModalOpened } = React.useContext(ModalContext)

  return (
    <Category categoryTitle="contacts">
      <div className="contacts-flex">
        <div className="contacts__title-bar">
          <div className="web-h1">
            Let’s <br />
            discuss your <br />
            project
          </div>
          <div className="contacts-button-container">
            <ContactUsButton onClick={() => setIsModalOpened(true)} />
          </div>
        </div>

        <div className="contacts-block">
          <div className="contacts-block__column contacts-block__column--left">
            <a
              className="web-h3 contacts__link"
              href="mailto:info@mountain-pass.com.au"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              info@mountain-pass.com.au
            </a>
            <a className="web-h3 contacts__link" href="tel:+61467546656">
              0467 546 656
            </a>
          </div>
          <div className="contacts-block__column">
            <a
              className="web-h3 contacts__link"
              href="https://fb.me/mountainpass"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Facebook
            </a>
            <a
              className="web-h3 contacts__link"
              href="https://au.linkedin.com/company/mountainpass"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Linkedin
            </a>
          </div>
        </div>

        <div className="web-p2-medium contacts__address">
          Sydney,
          <br />
          Level 21
          <br />
          207 Kent Street
          <br />
          Sydney NSW 2000
        </div>
      </div>
    </Category>
  )
}

export default ContactsPage
