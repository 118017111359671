import React from "react"
import "./contact-us-button.scss"
const classNames = require("classnames")

function ContactUsButton({ onClick, enableBlueCircle = true, fill = false }) {
  const text = () => {
    const baseText = "CONTACT US"
    const spaceSymbol = "\u00A0"
    const spacesCount = 18 // fill with needed count
    const spaces = Array(spacesCount).fill(spaceSymbol).join("")
    return Array(4).fill(`${baseText}${spaces}`).join("")
  }

  const black = "#1F1C20"

  return (
    <div className="dots-button">
      <div className="dot text">
        <svg
          viewBox="0 0 310 310"
          width="310"
          height="310"
          className={classNames("rotating-low", {
            "dots-button--fill": fill,
          })}
        >
          <defs>
            <path
              id="circle"
              d=" M 155, 155
              m -122, 0
              a 122,122 0 1,1 244,0
              a 122,122 0 1,1 -244,0"
            />
          </defs>
          <text fontSize="18">
            <textPath xlinkHref="#circle">{`${text()}`}</textPath>
          </text>
        </svg>
      </div>
      {enableBlueCircle && <div className="dot blue"></div>}
      <button className="dot green" onClick={onClick}>
        <svg
          width="52"
          height="52"
          viewBox="0 0 52 52"
          className="top-right-icon"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.355 5.85702L0.499969 47.712L4.28795 51.5L46.1429 9.645L46.1429 49.606H51.5L51.5 0.5H2.39396L2.39396 5.85702H42.355Z"
            fill={black}
          />
        </svg>
      </button>
    </div>
  )
}

export default ContactUsButton