import superRewards from "../../images/portfolio/big/Super Rewards.jpg";
import safeentry from "../../images/portfolio/big/Safeentry.jpg";
import australiaBank from "../../images/portfolio/big/Regional Australia bank.jpg";
import signIn from "../../images/portfolio/big/Global sign.in.jpg";
import kneerom from "../../images/portfolio/big/Kneerom.jpg";
import addressr from "../../images/portfolio/big/Addressr.jpg";
import sipora from "../../images/portfolio/big/Sipora.jpg";
import handleKate from "../../images/portfolio/big/Handle & kate.jpg";
import stayWell from "../../images/portfolio/big/My stay well.jpg";
import myRamsay from "../../images/portfolio/big/My Ramsay.jpg";
import myPatient from "../../images/portfolio/big/My patient +.jpg";

export default {superRewards, safeentry, australiaBank, signIn, kneerom, addressr, sipora, handleKate, stayWell, myRamsay, myPatient };