import * as React from "react"
import PortfolioMobileCard from "./portfolio-mobile-card"
import "./portfolio-mobile.scss"
import classnames from "classnames"
import arrowLeft from "../../icons/arrows/leftWhite.svg"
import arrowRight from "../../icons/arrows/rightWhite.svg"

function PortfolioMobilePage({ items }) {
  const itemsLength = items.length

  const [activeIndex, setActiveIndex] = React.useState(0)
  const [isDescriptionActive, setIsDescriptionActive] = React.useState(false)

  const getIndex = param => {
    const index = activeIndex + param

    if (index < 0) return itemsLength - 1
    if (index >= itemsLength) return 0
    return index
  }

  const nextItemIdx = () => {
    return getIndex(1)
  }

  const prevItemIdx = () => {
    return getIndex(-1)
  }

  return (
    <div className="portfolio--mobile">
      <div className="portfolio-mobile-page">
        <div className="web-p2-medium">
          {activeIndex >= 9
            ? `${activeIndex + 1} / ${itemsLength}`
            : `0${activeIndex + 1} / ${itemsLength}`}
        </div>

        <div className="portfolio-mobile-carousel">
          {items.map((item, index) => (
            <PortfolioMobileCard
              title={item.title}
              image={item.image}
              desc={item.desc}
              active={index === activeIndex}
              next={index === nextItemIdx()}
              prev={index === prevItemIdx()}
              index={index}
              tech={item.tech}
              key={index}
            />
          ))}
        </div>

        <div className="portfolio-mobile__bottom-bar">
          {items
            .map(e => e.title)
            .map((title, idx) => (
              <figcaption
                className={classnames(
                  "portfolio-mobile__card-title",
                  "web-h1",
                  {
                    "portfolio-mobile__card-title--active": idx === activeIndex,
                  }
                )}
                key={idx}
              >
                {title}
              </figcaption>
            ))}

          <button
            onClick={() => setIsDescriptionActive(true)}
            className={classnames("portfolio-mobile__btn", {
              "portfolio-mobile__btn--active": !isDescriptionActive,
            })}
          >
            <span>more</span>
            <img src={arrowRight} alt="more" />
          </button>

          <button
            onClick={() => setIsDescriptionActive( false)}
            className={classnames("portfolio-mobile__btn", {
              "portfolio-mobile__btn--active": isDescriptionActive,
            })}
          >
            <img src={arrowLeft} alt="more" />
            <span>back</span>
          </button>

          <p className="portfolio-mobile__bottom-bar-title mobile-p1-regular">
            <span>
              {activeIndex >= 9 ? activeIndex + 1 : `0${activeIndex + 1}`}
            </span>
          </p>

          <div className="portfolio-mobile__description-bar">
            {items
              .map(e => e.description)
              .map((title, idx) => (
                <p
                  className={classnames("portfolio-mobile__description", {
                    "portfolio-mobile__description--active":
                      idx === activeIndex && isDescriptionActive,
                  })}
                  key={idx}
                >
                  {title}
                </p>
              ))}

            <div
              className={classnames("portfolio-mobile-buttons", {
                "portfolio-mobile-buttons--active": !isDescriptionActive,
              })}
            >
              <div className="buttons">
                <button onClick={() => setActiveIndex(getIndex(-1))}>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    xmlns="http://www.w3.org/2000/svg"
                    className="bottom-left-icon"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.20902 19.3637L21.5883 1.98449L20.0154 0.411621L2.63615 17.7909L2.63615 1.19805H0.411783L0.411784 21.5881H20.8018L20.8018 19.3637H4.20902Z"
                      fill="white"
                    />
                  </svg>
                </button>
                <button onClick={() => setActiveIndex(getIndex(1))}>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    xmlns="http://www.w3.org/2000/svg"
                    className="top-right-icon"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.791 2.63603L0.411743 20.0153L1.98461 21.5881L19.3638 4.2089L19.3638 20.8017H21.5882L21.5882 0.411665H1.19818L1.19818 2.63603H17.791Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PortfolioMobilePage
