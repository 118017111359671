import * as React from "react"
import PropTypes from "prop-types"

import "./category.scss"
import classnames from 'classnames';

const Category = ({ categoryTitle, className, children }) => {
  return (
    <>
      <div className={classnames(categoryTitle, className)} id={categoryTitle}>
        <div className="web-h4 category-header">{categoryTitle}</div>
        {children}
      </div>
    </>
  )
}

Category.propTypes = {
  categoryTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Category.defaultProps = {
  categoryTitle: ``,
}

export default Category
